import { __awaiter, __generator } from "tslib";
import Vue from 'vue';
import { EnvDetector, RunningEnv, Storage, tools, TypeCast } from '@/utils/util';
export default Vue.extend({
    data: function () {
        return {
            env: RunningEnv.common,
            allTools: tools
        };
    },
    computed: {
        tools: function () {
            return this.allTools.filter(function (it) { return it.isFavorite; });
        }
    },
    mounted: function () {
        this.env = EnvDetector.detectEnv();
        document.title = this.$t('basic.title') + " - " + this.$t('favorites.title');
    },
    methods: {
        initData: function () {
            var _this = this;
            this.allTools.forEach(function (it) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b, _c, _d, _e, _f;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            _a = it;
                            _c = (_b = TypeCast).stringToBoolean;
                            return [4 /*yield*/, Storage.getItem(it.to + ":isFavorite", 'false')];
                        case 1:
                            _a.isFavorite = _c.apply(_b, [_g.sent()]);
                            _d = it;
                            _f = (_e = TypeCast).stringToBoolean;
                            return [4 /*yield*/, Storage.getItem(it.to + ":isPinned", 'false')];
                        case 2:
                            _d.isPinned = _f.apply(_e, [_g.sent()]);
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    }
});

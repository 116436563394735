import Vue from 'vue';
import { Storage } from '@/utils/util';
import axios from 'axios';
var requestHistory = [];
var method = 'GET';
export default Vue.extend({
    data: function () {
        return {
            isFavorite: false,
            isPinned: false,
            url: '',
            method: method,
            methodList: ['GET', 'POST'],
            postData: '',
            customHeaders: [{
                    name: '',
                    value: ''
                }],
            sendingRequest: false,
            message: {
                showing: false,
                color: 'error',
                title: ''
            },
            requestHistory: requestHistory
        };
    },
    mounted: function () {
        document.title = this.$t('basic.title') + " - " + this.$t('httpClient.title');
    },
    methods: {
        setFavorite: function () {
            this.isFavorite = !this.isFavorite;
            Storage.setItem('/http-client:isFavorite', String(this.isFavorite));
        },
        setPinned: function () {
            this.isPinned = !this.isPinned;
            Storage.setItem('/http-client:isPinned', String(this.isPinned));
        },
        addEmptyHeader: function () {
            this.customHeaders.push({ name: '', value: '' });
        },
        removeHeader: function (index) {
            this.customHeaders.splice(index, 1);
        },
        sendRequest: function () {
            var _this = this;
            if (!this.postSendingRequest()) {
                return;
            }
            this.sendingRequest = true;
            var headers = {};
            this.customHeaders.forEach(function (it) {
                if (it.name !== '' && it.value !== '') {
                    headers[it.name] = it.value;
                }
            });
            var config = {
                method: this.method,
                url: this.url,
                headers: headers
            };
            if (this.method.endsWith('POST')) {
                config.data = this.postData;
            }
            axios.request(config).then(function (res) {
                var requestHeaders = [];
                var responseHeaders = [];
                Object.keys(res.config.headers).forEach(function (name) {
                    requestHeaders.push({ name: name, value: res.config.headers[name] });
                });
                Object.keys(res.headers).forEach(function (name) {
                    responseHeaders.push({ name: name, value: res.headers[name] });
                });
                _this.requestHistory.push({
                    status: res.status,
                    method: res.config.method,
                    url: res.config.url,
                    data: res.data,
                    requestHeaders: requestHeaders,
                    responseHeaders: responseHeaders,
                    postData: _this.postData
                });
            }).catch(function (e) {
                var _a, _b;
                _this.requestHistory.push({
                    status: ((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) || -1,
                    method: e.config.method,
                    url: e.config.url,
                    data: undefined,
                    requestHeaders: e.config.headers,
                    responseHeaders: (_b = e.response) === null || _b === void 0 ? void 0 : _b.headers,
                    postData: _this.postData
                });
            }).finally(function () {
                _this.sendingRequest = false;
            });
        },
        postSendingRequest: function () {
            if (this.url === '') {
                this.showMessage(this.$t('httpClient.urlMustNotEmpty').toString(), 'error');
                return false;
            }
            return true;
        },
        showMessage: function (msg, color) {
            this.message.showing = true;
            this.message.color = color;
            this.message.title = msg;
        }
    }
});

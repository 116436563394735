import { __awaiter, __generator } from "tslib";
import Vue from 'vue';
import { Storage } from '@/utils/util';
export default Vue.extend({
    data: function () {
        return {
            languages: ['zh', 'en']
        };
    },
    methods: {
        setLanguage: function (lang) {
            return __awaiter(this, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            this.$i18n.locale = lang;
                            return [4 /*yield*/, Storage.setItem('app-lang', lang)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            console.log(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    },
    mounted: function () {
        document.title = this.$t('basic.title') + " - " + this.$t('settings.title');
    }
});

export default {
    basic: {
        title: '猫猫工具箱',
        pin: '置顶',
        unPin: '取消置顶',
        addToFavorites: '加入收藏夹',
        removeFromFavorites: '移出收藏夹'
    },
    home: {
        title: '主页'
    },
    favorites: {
        title: '收藏夹',
        noFavorites: '没有加入收藏夹的工具'
    },
    settings: {
        title: '设置',
        language: '语言',
        zh: '简体中文',
        en: 'English'
    },
    httpClient: {
        title: 'HTTP 客户端',
        requestUrl: '要请求的地址',
        postData: '要发送给服务器的数据',
        customHeader: '自定义请求头',
        fieldName: '字段名',
        fieldValue: '字段值',
        sendRequest: '发送请求',
        add: '添加',
        remove: '删除',
        requestMethod: '方法',
        urlMustNotEmpty: '请求地址不能为空',
        requestHistory: '历史请求',
        status: '状态码',
        responseHeader: '响应头',
        responseData: '响应数据',
        basicData: '基础信息',
        requestedUrl: '请求地址',
        requestData: '请求数据',
        requestHeader: '请求头'
    }
};

export default {
    basic: {
        title: 'NekoToolbox'
    },
    home: {
        title: 'Home'
    },
    favorites: {
        title: 'Favorites'
    },
    settings: {
        title: 'Settings',
        language: 'Language',
        zh: '简体中文',
        en: 'English'
    },
    httpClient: {
        title: 'HTTP Client'
    }
};

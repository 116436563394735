import Vue from 'vue';
export default Vue.extend({
    props: {
        inPopup: {
            type: Boolean,
            required: false,
            default: false
        },
        inOptions: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        title: function () {
            if (this.inPopup) {
                return this.$t('favorites.title');
            }
            else {
                return this.$t('basic.title');
            }
        }
    },
    data: function () {
        return {
            drawerOpen: false,
            navList: [
                { to: '/', icon: 'mdi-home', title: 'home.title' },
                { to: '/favorites', icon: 'mdi-folder-star', title: 'favorites.title' },
                { to: '/settings', icon: 'mdi-cog', title: 'settings.title' }
            ]
        };
    },
    methods: {
        goUrl: function (url) {
            browser.tabs.create({
                url: url
            });
        }
    }
});

import { __awaiter, __generator } from "tslib";
import * as localforage from 'localforage';
import Vue from 'vue';
export var RunningEnv;
(function (RunningEnv) {
    RunningEnv[RunningEnv["extension"] = 0] = "extension";
    RunningEnv[RunningEnv["common"] = 1] = "common";
})(RunningEnv || (RunningEnv = {}));
var EnvDetector = /** @class */ (function () {
    function EnvDetector() {
    }
    EnvDetector.detectEnv = function () {
        try {
            if (browser.storage) {
                return RunningEnv.extension;
            }
            return RunningEnv.common;
        }
        catch (e) {
            return RunningEnv.common;
        }
    };
    return EnvDetector;
}());
export { EnvDetector };
var Storage = /** @class */ (function () {
    function Storage() {
    }
    Storage.getItem = function (key, defaultValue) {
        return __awaiter(this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(EnvDetector.detectEnv() === RunningEnv.common)) return [3 /*break*/, 2];
                        return [4 /*yield*/, localforage.getItem(key)];
                    case 1:
                        res = _a.sent();
                        if (!res) {
                            return [2 /*return*/, defaultValue];
                        }
                        return [2 /*return*/, res];
                    case 2: return [2 /*return*/, new Promise(function (resolve) {
                            chrome.storage.local.get(function (it) {
                                if (it[key]) {
                                    resolve(it[key]);
                                }
                                else {
                                    resolve(defaultValue);
                                }
                            });
                        })];
                }
            });
        });
    };
    Storage.setItem = function (key, value) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(EnvDetector.detectEnv() === RunningEnv.common)) return [3 /*break*/, 2];
                        return [4 /*yield*/, localforage.setItem(key, value)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2: return [2 /*return*/, new Promise(function (resolve, reject) {
                            var obj = {};
                            obj[key] = value;
                            chrome.storage.local.set(obj, function () {
                                var error = chrome.runtime.lastError;
                                if (error) {
                                    reject(error);
                                }
                                else {
                                    resolve();
                                }
                            });
                        })];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return Storage;
}());
export { Storage };
export var HookedVue = Vue.extend({
    mounted: function () {
        var _this = this;
        Storage.getItem('app-lang', 'zh').then(function (lang) {
            _this.$i18n.locale = lang;
        });
    }
});
var TypeCast = /** @class */ (function () {
    function TypeCast() {
    }
    TypeCast.stringToBoolean = function (str) {
        return str === 'true';
    };
    return TypeCast;
}());
export { TypeCast };
var Tool = /** @class */ (function () {
    function Tool(to, title, isFavorite, isPinned) {
        this.to = to;
        this.isFavorite = isFavorite;
        this.isPinned = isPinned;
        this.title = title;
    }
    Tool.prototype.setFavorite = function (isFavorite) {
        this.isFavorite = isFavorite;
        Storage.setItem(this.to + ":isFavorite", String(isFavorite)).then();
    };
    Tool.prototype.setPinned = function (isPinned) {
        this.isPinned = isPinned;
        Storage.setItem(this.to + ":isPinned", String(isPinned)).then();
    };
    return Tool;
}());
export { Tool };
export var tools = [
    new Tool('/http-client', 'httpClient.title', false, false)
];
